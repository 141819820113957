<template>
  <div class="voluteer-container" v-loading="loading">
    <div class="voluteer-crumbs">
      <router-link :to="{ name: 'culture_homepage' }">首页 / </router-link>
      <div>志愿服务</div>
    </div>
    <div class="voluteer-info">
      <listItem
        listType="volunteer"
        :list="rows"
        v-loading="loading"
      ></listItem>
    </div>
  </div>
</template>

<script>
import { voluteerList } from "@/api/common";
import ListItem from "@/views/components/listItem";
export default {
  data() {
    return {
      rows: [],
      listQuery: {
        aid: process.env.VUE_APP_AID,
        page: 1,
        pageSize: 6,
      },
      loading: true,
    };
  },
  components: {
    ListItem,
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let self = this;
      voluteerList(self.listQuery)
        .then((res) => {
          if (res.code == 0) {
            this.rows = res.data.datalist;
            self.loading = false;
          }
        })
        .catch(() => {
          self.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/voluteer.scss";
</style>
